export default [
  {
    path: '/mentor-portal/skill-dev',
    name: 'mentor-skill-dev',
    component: () => import('@/views/mentor-portal/opportunities/skill-dev/Manager.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Skill Development',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Skill Development',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/skill-dev/my-programs',
    name: 'mentor-skill-dev',
    component: () => import('@/views/mentor-portal/opportunities/skill-dev/AllSkillDev.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'My Programs',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Skill Development',
          active: true,
        },
        {
          text: 'My Programs',
          active: true,
        },
      ],
    },
  },
]
