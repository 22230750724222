export default [
  {
    path: '/startup-portal/business-details',
    name: 'startup-portal-business-details',
    component: () => import('@/views/startup-portal/pages/business-details/index.vue'),
    meta: {
      pageTitle: 'Business Details',
      breadcrumb: [
        {
          text: 'Performance',
        },
        {
          text: 'Business Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/business-details/:bdid/evaluation/:vid/:vname',
    name: 'startup-portal-business-details-evaluation',
    component: () => import('@/views/startup-portal/pages/business-details/evaluation.vue'),
    meta: {
      pageTitle: 'Business Details',
      breadcrumb: [
        {
          text: 'Performance',
        },
        {
          text: 'Business Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/milestones',
    name: 'startup-portal-milestones',
    component: () => import('@/views/startup-portal/pages/milestones/index.vue'),
    meta: {
      pageTitle: 'Milestones',
      breadcrumb: [
        {
          text: 'Performance',
        },
        {
          text: 'Milestones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal-growth',
    name: 'startup-portal-growth',
    component: () => import('@/views/startup-portal/pages/dashboard/Growth.vue'),
    meta: {
      pageTitle: 'Growth',
      breadcrumb: [
        {
          text: 'Performance',
        },
        {
          text: 'Growth',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal-growth/add/:gid',
    name: 'startup-portal-growth-add',
    component: () => import('@/views/startup-portal/pages/dashboard/AddGrowth.vue'),
    meta: {
      pageTitle: 'Add Growth',
      breadcrumb: [
        {
          text: 'Performance',
        },
        {
          text: 'Add Growth',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal-growth/view/:id',
    name: 'startup-portal-growth-view',
    component: () => import('@/views/startup-portal/pages/dashboard/ViewGrowth.vue'),
    meta: {
      pageTitle: 'View Growth',
      breadcrumb: [
        {
          text: 'Performance',
        },
        {
          text: 'Add Growth',
          active: true,
        },
      ],
    },
  },
]
