const data = [
  // Manage Organizations
  {
    path: '/partner-portal/sessions',
    name: 'partner-sessions',
    component: () => import('@/views/partner-portal/ManageSessions.vue'),
    meta: {
      pageTitle: 'Sessions',
      breadcrumb: [
        {
          text: 'Sessions',
        },
        {
          text: 'Sessions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partner-portal/organizations',
    name: 'partner-organizations',
    component: () => import('@/views/partner-portal/organizations/ManageOrganizations.vue'),
    meta: {
      pageTitle: 'My Organizations',
      breadcrumb: [
        {
          text: 'Partner',
        },
        {
          text: 'Organizations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partner-portal/organizations/add',
    name: 'add-organizations',
    component: () => import('@/views/partner-portal/organizations/AddOrganizations.vue'),
    meta: {
      pageTitle: 'Add Organization',
      breadcrumb: [
        {
          text: 'Partner',
        },
        {
          text: 'Organizations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/partner-portal/organizations/:id/update',
    name: 'update-organizations',
    component: () => import('@/views/partner-portal/organizations/AddOrganizations.vue'),
    meta: {
      pageTitle: 'Update Organization',
      breadcrumb: [
        {
          text: 'Partner',
        },
        {
          text: 'Organizations',
          active: true,
        },
      ],
    },
  },
  // List Incubator
  {
    path: '/partner-portal/incubators',
    name: 'partner-incubators',
    component: () => import('@/views/partner-portal/incubators/ManageIncubators.vue'),
    meta: {
      pageTitle: 'Manage Incubator',
      breadcrumb: [
        {
          text: 'Partner',
        },
        {
          text: 'Incubators',
          active: true,
        },
      ],
    },
  },
  // Incubator Manager
  {
    path: '/partner-portal/incubator/:id/manage',
    name: 'partner-incubator-manager',
    component: () => import('@/views/partner-portal/incubators/incubator-manager/index.vue'),
    meta: {
      pageTitle: 'Manage Incubator',
      breadcrumb: [
        {
          text: 'Partner',
        },
        {
          text: 'Incubator Manager',
          active: true,
        },
      ],
    },
  },
]

export default data
