export default [
  {
    path: '/mentor-portal-invitations',
    name: 'mentor-invitations',
    component: () => import('@/views/mentor-portal/invitations/Invitation.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Invitations',
      breadcrumb: [
        {
          text: 'Invitations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/invitations/programs',
    name: 'mentor-programs-invitations',
    component: () => import('@/views/mentor-portal/invitations/program-invitation/ProgramInvitation.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Programs Invitation',
      breadcrumb: [
        {
          text: 'Invitations',
        },
        {
          text: 'Programs Invitation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/invitations/events',
    name: 'mentor-event-invitation',
    component: () => import('@/views/mentor-portal/invitations/event-invitation/EventInvitation.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Events Invitation',
      breadcrumb: [
        {
          text: 'Invitations',
        },
        {
          text: 'Events Invitation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/invitations/competitions',
    name: 'mentor-competition-invitation',
    component: () => import('@/views/mentor-portal/invitations/competition-invitation/CompetitionInvitation.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Competitions Invitation',
      breadcrumb: [
        {
          text: 'Invitations',
        },
        {
          text: 'Competitions Invitation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mentor-portal/invitations/details/:pid/:apid/:aid?',
    name: 'mentor-application-form',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
    },
  },
]
