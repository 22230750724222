export default [
  {
    path: '/programs',
    name: 'manage-programs',
    component: () => import('@/views/incubator-portal/programs/pages/startups/ManagePrograms.vue'),
    meta: {
      pageTitle: 'Startup Programs',
      breadcrumb: [
        {
          text: 'Startup Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/events/add',
    name: 'add-new-events',
    component: () => import('@/views/incubator-portal/programs/pages/startups/AddEvents.vue'),
    meta: {
      pageTitle: 'Add Event',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'manage-events' },
        },
        {
          text: 'Add Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/competitions/add',
    name: 'add-competition',
    component: () => import('@/views/incubator-portal/programs/pages/startups/AddCompetitions.vue'),
    meta: {
      pageTitle: 'Add Competition',
      breadcrumb: [
        {
          text: 'Competition',
          to: { name: 'manage-competitions' },
        },
        {
          text: 'Add Competition',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/add',
    name: 'add-programs',
    component: () => import('@/views/incubator-portal/programs/pages/startups/AddPrograms.vue'),
    meta: {
      pageTitle: 'Add Program',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Add Program',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:id',
    name: 'view-programs',
    component: () => import('@/views/incubator-portal/programs/pages/common/ViewPrograms.vue'),
    meta: {
      pageTitle: 'View Program',
      breadcrumb: [
        {
          text: 'Startup Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'View Program',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/events/:id/manage',
    name: 'events-program-manager',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/Eventsindex.vue'),
    meta: {
      pageTitle: 'Manage Events',
      breadcrumb: [
        {
          text: 'Events',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Manage Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:id/manage',
    name: 'program-manager',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/index.vue'),
    meta: {
      pageTitle: 'Manage Startup Programs',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Manage Startup Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/events/:id/manage',
    name: 'event-dashboard-manager',
    component: () => import('@/views/startup-portal/pages/programs/startup-program-manager/index.vue'),
    meta: {
      pageTitle: 'Event Manager',
      breadcrumb: [
        {
          text: 'opportunities',
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/competition/:id/manage',
    name: 'competition-dashboard-manager',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/index.vue'),
    meta: {
      pageTitle: 'Competition Manager',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:id/:mid/manage',
    name: 'program-manager-mid',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/index.vue'),
    meta: {
      pageTitle: 'Program Manager',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:id/manage/:wid',
    name: 'program-manager-wid',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/index.vue'),
    meta: {
      pageTitle: 'Program Manager',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/application/:id/manage',
    name: 'application-manager',
    component: () => import('@/views/incubator-portal/programs/pages/startups/application-manager/index.vue'),
    meta: {
      pageTitle: 'Application Manager',
      breadcrumb: [
        {
          text: 'Applications',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/events/application/:id/manage',
    name: 'events-application-manager',
    component: () => import('@/views/incubator-portal/programs/pages/startups/application-manager/Eventsindex.vue'),
    meta: {
      pageTitle: 'Application Manager',
      breadcrumb: [
        {
          text: 'Applications',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/competitions/application/:id/manage',
    name: 'competitions-application-manager',
    component: () => import('@/views/incubator-portal/programs/pages/startups/application-manager/Competitionsindex.vue'),
    meta: {
      pageTitle: 'Manage Competitions',
      breadcrumb: [
        {
          text: 'Competitions',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Manage Competitions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/skill-dev/application/:id/manage',
    name: 'skill-dev-application-manager',
    component: () => import('@/views/incubator-portal/programs/pages/students/program-manager/application-manager/index.vue'),
    meta: {
      pageTitle: 'Skill Dev Manager',
      breadcrumb: [
        {
          text: 'Skill Dev',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    // PID = Program, APID = Application ID, AID = Applicant ID
    path: '/programs/:pid/applications/:apid/apply/:aid?',
    name: 'application-question',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    // PID = Program, APID = Application ID, AID = Applicant ID, RID = Exerciseer/Partner ID
    path: '/programs/:pid/applications/:apid/applicant/:aid/partner/:rid',
    name: 'application-score',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ApplicationScore.vue'),
    meta: {
      pageTitle: 'Score Response',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/applications/:aid',
    name: 'view-applications',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ViewApplications.vue'),
    meta: {
      pageTitle: 'View Applications',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/events/:pid/applications/:aid',
    name: 'events-view-applications',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/EventsViewApplications.vue'),
    meta: {
      pageTitle: 'View Applications',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/competitions/programs/:pid/applications/:aid',
    name: 'competitions-view-applications',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/CompetitionsViewApplications.vue'),
    meta: {
      pageTitle: 'View Applications',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/applications/:apid/applicant/:aid/evaluations/',
    name: 'view-evaluation',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Application Evaluation',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/reviews/:aid',
    name: 'view-reviews',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ViewReview.vue'),
    meta: {
      pageTitle: 'Startup Exercises',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Exercise Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/reviews/:apid/applicant/:aid?',
    name: 'review-questions',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ReviewQuestion.vue'),
    meta: {
      pageTitle: 'Exercise Questions',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Exercise Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/manage/:aid',
    name: 'view-programs-manager',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/master.vue'),
    meta: {
      pageTitle: 'Manage Program',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/growth/:aid',
    name: 'view-growth',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ViewReview.vue'),
    meta: {
      pageTitle: 'Startup Growth',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Exercise Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/feedbacks/:aid',
    name: 'view-feedbacks',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ViewReview.vue'),
    meta: {
      pageTitle: 'Startup Feedbacks',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Exercise Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/requests/:aid',
    name: 'view-requests',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ViewReview.vue'),
    meta: {
      pageTitle: 'Startup Requests',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Exercise Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/emeets/:aid',
    name: 'view-emeets',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/emeet-manager/ViewEmeets.vue'),
    meta: {
      pageTitle: 'Startup E-Meets',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Exercise Manager',
          active: true,
        },
      ],
    },
  },
  {
    // APID = Review ID, AID = Participant ID, RID = Reviewer/Partner ID
    path: '/programs/:pid/reviews/:apid/applicant/:aid/partner/:rid',
    name: 'review-score',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ReviewScore.vue'),
    meta: {
      pageTitle: 'Score Response',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Exercise Manager',
          active: true,
        },
      ],
    },
  },
  {
    // APID = Review ID, AID = Participant ID, RID = Reviewer/Partner ID
    path: '/programs/:pid/reviews/:apid/applicant/:aid/evaluations/',
    name: 'review-evaluations',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Review Evaluations',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Exercise Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/progress/:id',
    name: 'progress-startups',
    component: () => import('@/views/incubator-portal/programs/pages/common/program-manager/progress-stages/ViewStartups.vue'),
    meta: {
      pageTitle: 'View Startups',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Progress Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/progress/:id/reviews/:aid/',
    name: 'progress-startups-reviews',
    component: () => import('@/views/incubator-portal/programs/pages/common/program-manager/progress-stages/ViewReview.vue'),
    meta: {
      pageTitle: 'View Reviews',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Progress Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/milestone/:aid/',
    name: 'progress-milestone',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/milestone-manager/MilestoneManager.vue'),
    meta: {
      pageTitle: 'Milestone Manager',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Milestone Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/programs/:pid/milestone/:aid/stages/:id',
    name: 'progress-milestone-stages',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/milestone-manager/MilestoneStages.vue'),
    meta: {
      pageTitle: 'Milestone Stages',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Milestone Manager',
          active: true,
        },
      ],
    },
  },

  // ====== Skill Dev Programs ======
  {
    path: '/skill-dev-programs',
    name: 'manage-skill-dev-programs',
    component: () => import('@/views/incubator-portal/programs/pages/students/ManagePrograms.vue'),
    meta: {
      pageTitle: 'Skill Development Programs',
      breadcrumb: [
        {
          text: 'Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/skill-dev-programs/add',
    name: 'add-skill-dev-programs',
    component: () => import('@/views/incubator-portal/programs/pages/students/AddPrograms.vue'),
    meta: {
      pageTitle: 'Add Program',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Add Program',
          active: true,
        },
      ],
    },
  },
  {
    path: '/skill-dev-programs/:id/manage',
    name: 'skill-dev-program-manager',
    component: () => import('@/views/incubator-portal/programs/pages/students/program-manager/index.vue'),
    meta: {
      pageTitle: 'Manage Skill Dev Program',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Manage Skill Dev Program',
          active: true,
        },
      ],
    },
  },
  {
    path: '/skill-dev-programs/:program_id/exercise/:assignment_id/students',
    name: 'sdp-exercise-students-responses',
    component: () => import('@/views/incubator-portal/programs/pages/students/program-manager/exercise-manager/StudentSubmissions.vue'),
    meta: {
      pageTitle: 'Students Responses',
      breadcrumb: [
        {
          text: 'Skill Development',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Exercises',
        },
        {
          text: 'Students\' Responses',
          active: true,
        },
      ],
    },
  },
  // ====== Skill Dev Programs - Applications ======
  {
    // PID = Program, APID = Application ID, AID = Applicant ID
    path: '/skill-dev-programs/:pid/applications/:apid/apply/:aid?',
    name: 'skill-dev-application-question',
    component: () => import('@/views/incubator-portal/programs/pages/students/program-manager/application-manager/ApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    // PID = Program, APID = Application ID, AID = Applicant ID, RID = Reviewer/Partner ID
    path: '/skill-dev-programs/:pid/applications/:apid/applicant/:aid/partner/:rid',
    name: 'skill-dev-application-score',
    component: () => import('@/views/incubator-portal/programs/pages/students/program-manager/application-manager/ApplicationScore.vue'),
    meta: {
      pageTitle: 'Score Response',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/skill-dev-programs/:pid/applications/:aid',
    name: 'skill-dev-view-applications',
    component: () => import('@/views/incubator-portal/programs/pages/students/program-manager/application-manager/ViewApplications.vue'),
    meta: {
      pageTitle: 'View Applications',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/skill-dev-programs/:pid/applications/:apid/applicant/:aid/evaluations/',
    name: 'skill-dev-view-evaluation',
    component: () => import('@/views/incubator-portal/programs/pages/students/program-manager/application-manager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Application Evaluation',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Application Manager',
          active: true,
        },
      ],
    },
  },
  // ====== Skill Dev Programs - Reviews ======
  {
    path: '/skill-dev-programs/:pid/reviews/:aid',
    name: 'view-reviews',
    component: () => import('@/views/incubator-portal/programs/pages/students/program-manager/review-manager/ViewReview.vue'),
    meta: {
      pageTitle: 'Startup Exercises',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Exercise Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/skill-dev-programs/:pid/reviews/:apid/applicant/:aid?',
    name: 'review-questions',
    component: () => import('@/views/incubator-portal/programs/pages/students/program-manager/review-manager/ReviewQuestion.vue'),
    meta: {
      pageTitle: 'Exercise Questions',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Exercise Manager',
          active: true,
        },
      ],
    },
  },
  {
    // APID = Review ID, AID = Participant ID, RID = Reviewer/Partner ID
    path: '/skill-dev-programs/:pid/reviews/:apid/applicant/:aid/partner/:rid',
    name: 'review-score',
    component: () => import('@/views/incubator-portal/programs/pages/students/program-manager/review-manager/ReviewScore.vue'),
    meta: {
      pageTitle: 'Score Response',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Exercise Manager',
          active: true,
        },
      ],
    },
  },
  {
    // APID = Review ID, AID = Participant ID, RID = Reviewer/Partner ID
    path: '/skill-dev-programs/:pid/reviews/:apid/applicant/:aid/evaluations/',
    name: 'review-evaluations',
    component: () => import('@/views/incubator-portal/programs/pages/students/program-manager/review-manager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Exercise Evaluations',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'manage-skill-dev-programs' },
        },
        {
          text: 'Program Manager',
        },
        {
          text: 'Exercise Manager',
          active: true,
        },
      ],
    },
  },
]
