export default [
  {
    path: '/incubator-portal/details',
    name: 'manage-incubator',
    component: () => import('@/views/incubator-portal/ManageIncubator.vue'),
    meta: {
      pageTitle: 'Incubator Manager',
      breadcrumb: [
        {
          text: 'Incubator Manager',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/events',
    name: 'event-details',
    component: () => import('@/views/incubator-portal/ManageEvents.vue'),
    meta: {
      pageTitle: 'Events',
      breadcrumb: [
        {
          text: 'Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/competitions',
    name: 'manage-competitions',
    component: () => import('@/views/incubator-portal/ManageCompetitions.vue'),
    meta: {
      pageTitle: 'Competitions',
      breadcrumb: [
        {
          text: 'Competitions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/communications',
    name: 'communications-details',
    component: () => import('@/views/incubator-portal/ManageCommunications.vue'),
    meta: {
      pageTitle: 'Communication Details',
      breadcrumb: [
        {
          text: 'Communications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/sessions',
    name: 'sessions-manager',
    component: () => import('@/views/incubator-portal/sessions/index.vue'),
    meta: {
      pageTitle: 'Sessions',
      breadcrumb: [
        {
          text: 'Sessions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/requests',
    name: 'request-details',
    component: () => import('@/views/incubator-portal/ManageCommunications.vue'),
    meta: {
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Requests',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/partner-services',
    name: 'partner-services-details',
    component: () => import('@/views/incubator-portal/partner-services/ManagePartnerServices.vue'),
    meta: {
      pageTitle: 'Partner Services',
      breadcrumb: [
        {
          text: 'Partner Services',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/partner-services/:sid/',
    name: 'partner-services-requests',
    component: () => import('@/views/incubator-portal/partner-services/ManageRequests.vue'),
    meta: {
      pageTitle: 'Partner Service Details',
      breadcrumb: [
        {
          text: 'Partner Services',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/library',
    name: 'library-module-details',
    component: () => import('@/views/incubator-portal/ManageLibraryModules.vue'),
    meta: {
      pageTitle: 'Library',
      breadcrumb: [
        {
          text: 'Library',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/library/:lid/:mname',
    name: 'library-details',
    component: () => import('@/views/incubator-portal/ManageLibrary.vue'),
    meta: {
      pageTitle: 'Library Details',
      breadcrumb: [
        {
          text: 'Library',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/requests',
    name: 'all-requests',
    component: () => import('@/views/incubator-portal/ManageRequests.vue'),
    meta: {
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Requests',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/feedbacks',
    name: 'all-feedbacks',
    component: () => import('@/views/incubator-portal/ManageFeedbacks.vue'),
    meta: {
      pageTitle: 'Feedback',
      breadcrumb: [
        {
          text: 'Feedback',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/feedbacks/:fid/preview',
    name: 'feedbacks-preview',
    component: () => import('@/views/incubator-portal/ManageFeedbackPreview.vue'),
    meta: {
      pageTitle: 'Feedback',
      breadcrumb: [
        {
          text: 'All Feedbacks',
        },
        {
          text: 'Feedbacks Preview',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/feedbacks/:fid/preview',
    name: 'feedbacks-preview',
    component: () => import('@/views/incubator-portal/ManageFeedbackPreview.vue'),
    meta: {
      pageTitle: 'Feedback',
      breadcrumb: [
        {
          text: 'All Feedbacks',
        },
        {
          text: 'Feedbacks Preview',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/growth',
    name: 'manage-growth',
    component: () => import('@/views/incubator-portal/ManageGrowth.vue'),
    meta: {
      pageTitle: 'Manage Growth',
      breadcrumb: [
        {
          text: 'Manage Growth',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/forms',
    name: 'forms-module-details',
    component: () => import('@/views/incubator-portal/ManageFormsModules.vue'),
    meta: {
      pageTitle: 'Forms',
      breadcrumb: [
        {
          text: 'Forms',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/forms/preview/:fid/:mname',
    name: 'forms-details-preview',
    component: () => import('@/views/incubator-portal/ManageFormPreview.vue'),
    meta: {
      pageTitle: 'Form Details',
      breadcrumb: [
        {
          text: 'Forms',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/forms/responses/:fid/:mname',
    name: 'forms-responses',
    component: () => import('@/views/incubator-portal/ManageFormsResponses.vue'),
    meta: {
      pageTitle: 'Form Responses',
      breadcrumb: [
        {
          text: 'Forms',
        },
        {
          text: 'Forms Responses',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/forms/mentors/:fid/:mname',
    name: 'forms-mentors',
    component: () => import('@/views/incubator-portal/ManageFormMentors.vue'),
    meta: {
      pageTitle: 'Jury Details',
      breadcrumb: [
        {
          text: 'Forms',
        },
        {
          text: 'Form Jury',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/forms/responses/:fid/:mname/response/:sid/:sname',
    name: 'forms-responses-response',
    component: () => import('@/views/incubator-portal/ViewFormsResponse.vue'),
    meta: {
      pageTitle: 'Form Response',
      breadcrumb: [
        {
          text: 'Forms',
        },
        {
          text: 'Forms Responses',
        },
        {
          text: 'Forms Response',
          active: true,
        },
      ],
    },
  },
  {
    path: '/incubator-portal/forms/responses/:fid/:mname/evaluation/:rid',
    name: 'forms-responses-evaluation',
    component: () => import('@/views/incubator-portal/ViewFormEvaluation.vue'),
    meta: {
      pageTitle: 'Form Responses',
      breadcrumb: [
        {
          text: 'Forms',
        },
        {
          text: 'Forms Responses',
        },
        {
          text: 'Forms Evaluation',
          active: true,
        },
      ],
    },
  },
]
