import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Ripple from 'vue-ripple-directive'
import {
  BadgePlugin,
  ButtonPlugin,
  BVConfigPlugin,
  CardPlugin,
  FormPlugin,
  LayoutPlugin,
  LinkPlugin,
  ModalPlugin,
  OverlayPlugin,
  ToastPlugin,
  VBTooltipPlugin,
} from 'bootstrap-vue'

import VCalendar from 'v-calendar'

// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'

import { $themeConfig } from '../themeConfig'

import EntityModalPlugin from './plugins/entity-modal-plugin'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.directive('ripple', Ripple)

Vue.use(BVConfigPlugin, {
  BOverlay: { spinnerVariant: 'primary', rounded: 'sm', variant: $themeConfig.layout.skin },
  BModal: { cancelVariant: 'flat-danger' },
})
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(OverlayPlugin)
Vue.use(LayoutPlugin)
Vue.use(VBTooltipPlugin)
Vue.use(FormPlugin)
Vue.use(ButtonPlugin)
Vue.use(CardPlugin)
Vue.use(LinkPlugin)
Vue.use(BadgePlugin)

Vue.use(VCalendar)

// Global Modal for Entity Details
Vue.use(EntityModalPlugin)

// Install this library
Vue.prototype.$moment = moment
