import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import resources from './routes/resources'
import events from './routes/events'
import teams from './routes/teams'
import startups from './routes/incubator-portal/startups'
import tickets from './routes/tickets'
import finances from './routes/finances'
import fundings from './routes/fundings'
import duediligence from './routes/duediligence'
import mentors from './routes/incubator-portal/mentors'
import partners from './routes/incubator-portal/partners'
import students from './routes/incubator-portal/students'
import campaigns from './routes/campaigns'
import programs from './routes/incubator-portal/programs'
import incubators from './routes/incubator-portal/my-incubator'
import studentPortal from './routes/student-portal'
import partnerPortal from './routes/partner-portal'
import mentorPortal from './routes/mentor-portal'
import startupPortal from './routes/startup-portal'
import opportunity from './routes/startup-portal/opportunity'
import performance from './routes/startup-portal/performance'
import meet from './routes/meet'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'dashboard' },
    },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...resources.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...finances.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...fundings.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...events.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...teams.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...startups.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...duediligence.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...tickets.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...mentors.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...partners.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...students.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...campaigns.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...programs.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...incubators.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...startupPortal.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'User',
        action: 'read',
      },
    })),
    ...opportunity.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'User',
        action: 'read',
      },
    })),
    ...performance.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'User',
        action: 'read',
      },
    })),
    ...studentPortal.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'User',
        action: 'read',
      },
    })),
    ...mentorPortal.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Mentor',
        action: 'read',
      },
    })),
    ...partnerPortal.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Mentor',
        action: 'read',
      },
    })),
    ...meet,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to log in if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { redirect: encodeURIComponent(to.fullPath) } })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  return next()
})

export default router
