export default [
  {
    path: '/students',
    name: 'manage-students',
    component: () => import('@/views/incubator-portal/students/pages/ManageStudents.vue'),
    meta: {
      pageTitle: 'Manage Students',
      breadcrumb: [
        {
          text: 'Students',
          active: true,
        },
      ],
    },
  },
  {
    path: '/students/add',
    name: 'add-students',
    component: () => import('@/views/incubator-portal/students/pages/AddStudents.vue'),
    meta: {
      pageTitle: 'Invite Student',
      breadcrumb: [
        {
          text: 'Students',
          to: { name: 'manage-students' },
        },
        {
          text: 'Invite Student',
          active: true,
        },
      ],
    },
  },
  {
    path: '/students/:id',
    name: 'view-students',
    component: () => import('@/views/incubator-portal/students/pages/AddStudents.vue'),
    meta: {
      pageTitle: 'Update Student',
      breadcrumb: [
        {
          text: 'Students',
          to: { name: 'manage-students' },
        },
        {
          text: 'Add Student',
          active: true,
        },
      ],
    },
  },
  {
    path: '/students/:id/manage',
    name: 'student-manager',
    component: () => import('@/views/incubator-portal/students/pages/student-manager/index.vue'),
    meta: {
      pageTitle: 'Student Manager',
      breadcrumb: [
        {
          text: 'Students',
          to: { name: 'manage-students' },
        },
        {
          text: 'Student Manager',
          active: true,
        },
      ],
    },
  },
  // About Student
  {
    path: '/students/:id/about',
    name: 'student-about',
    component: () => import('@/views/incubator-portal/students/pages/student-manager/About.vue'),
    meta: {
      pageTitle: 'Student Details',
      breadcrumb: [
        {
          text: 'Students',
          to: { name: 'manage-students' },
        },
        {
          text: 'Student Manager',
          to: { name: 'student-manager' },
        },
        {
          text: 'About',
          active: true,
        },
      ],
    },
  },
]
