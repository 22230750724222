import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { setContext } from 'apollo-link-context'
import { Auth } from 'aws-amplify'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { ApolloLink, split } from 'apollo-link'

Vue.use(VueApollo)

const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'https://starworks-erp.hasura.app/v1/graphql'
const wsEndpoint = process.env.VUE_APP_GRAPHQL_WS || 'wss://starworks-erp.hasura.app/v1/graphql'

const authLink = setContext(async (_, { headers }) => {
  const token = await Auth.currentSession().then(res => `Bearer ${res.getIdToken().getJwtToken()}`)
  return { headers: { ...headers, authorization: token || '' } }
})

const httpLink = new BatchHttpLink({ uri: httpEndpoint })

const wsLink = new WebSocketLink({
  uri: wsEndpoint,
  options: {
    reconnect: true,
    lazy: true,
    timeout: 30000,
    inactivityTimeout: 30000,
    connectionParams: async () => {
      const token = await Auth.currentSession().then(res => res.getIdToken().getJwtToken())
      return { headers: { authorization: `Bearer ${token}` } }
    },
  },
})

const link = ApolloLink.from([
  authLink,
  split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink,
  ),
])

const defaultOptions = {
  link,
  cache: new InMemoryCache({ addTypename: false }),
}

export const newApolloClient = createApolloClient({ ...defaultOptions }).apolloClient

export function createProvider(options = {}) {
  const { apolloClient, wsClient } = createApolloClient({ ...defaultOptions, ...options })
  apolloClient.wsClient = wsClient

  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: { $query: {} },
    errorHandler: error => error,
  })
}
