export default [
  {
    path: '/startup-portal/programs',
    name: 'startup-program',
    component: () => import('@/views/startup-portal/pages/programs/Programs.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Startup Programs',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
          to: { name: 'startup-portal-program' },
        },
        {
          text: 'Programs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/events',
    name: 'startup-portal/events',
    component: () => import('@/views/startup-portal/pages/programs/Events.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'My Upcoming Events',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
          to: { name: 'startup-portal-events' },
        },
        {
          text: 'My Upcoming Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/event-registration',
    name: 'startup-portal/event-registration',
    component: () => import('@/views/startup-portal/pages/programs/EventsRegistration.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Open for Registration',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
          to: { name: 'startup-portal-events' },
        },
        {
          text: 'Open for Registration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/event-application',
    name: 'startup-portal/event-application',
    component: () => import('@/views/startup-portal/pages/programs/EventsApplication.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'My Applications',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
          to: { name: 'startup-portal-events' },
        },
        {
          text: 'My Applications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/competition',
    name: 'startup-program/competition',
    component: () => import('@/views/startup-portal/pages/programs/competition.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'My Upcoming Competitions',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
          to: { name: 'startup-portal-competition' },
        },
        {
          text: 'My Upcoming Competitions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/competition-registration',
    name: 'startup-program/registration',
    component: () => import('@/views/startup-portal/pages/programs/competition.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Open for Registration',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
          to: { name: 'startup-portal-competition' },
        },
        {
          text: 'Open for Registration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/:id',
    name: 'startup-view-programs',
    component: () => import('@/views/incubator-portal/programs/pages/common/ViewPrograms.vue'),
    meta: {
      pageTitle: 'View Program',
      breadcrumb: [
        {
          text: 'Programs',
        },
        {
          text: 'View Program',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/select/:id',
    name: 'program-enter',
    component: () => import('@/views/startup-portal/pages/programs/SelectOrganisation.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Select Startup',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
          to: { name: 'startup-portal-program' },
        },
        {
          text: 'Select Startup',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/events/select/:id',
    name: 'event-enter',
    component: () => import('@/views/startup-portal/pages/programs/SelectEvent.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Select Event',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
          to: { name: 'startup-portal-events' },
        },
        {
          text: 'Select Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/competitions/select/:id',
    name: 'competition-enter',
    component: () => import('@/views/startup-portal/pages/programs/SelectCompetition.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Select Competition',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
          to: { name: 'startup-portal-competition' },
        },
        {
          text: 'Select Competition',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/enter/:id/:aid?',
    name: 'startup-programs',
    component: () => import('@/views/startup-portal/pages/programs/enter/index.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Startup Programs',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
          to: { name: 'startup-portal-program' },
        },
        {
          text: 'Program',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/events/enter/:id/:aid?',
    name: 'startup-events',
    component: () => import('@/views/startup-portal/pages/programs/enter/EventIndex.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Event Manager',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
          to: { name: 'startup-portal-events' },
        },
        {
          text: 'Event',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/competitions/enter/:id/:cid/:aid?',
    name: 'startup-competitions',
    component: () => import('@/views/startup-portal/pages/programs/enter/CompetitionIndex.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Competition Manager',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
          to: { name: 'startup-portal-competition' },
        },
        {
          text: 'Competition',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/enter/:id/:aid/:prid',
    name: 'progress-review',
    component: () => import('@/views/startup-portal/pages/programs/enter/ProgressReview.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Progress Review',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'startup-portal-competition' },
        },
        {
          text: 'Progress Review',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/milestone/:id/:mid/:aid?',
    name: 'startup-milestone-stages',
    component: () => import('@/views/startup-portal/pages/programs/enter/milestone-manager/MilestoneStages.vue'),
    meta: {
      pageTitle: 'Milestone Stages',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'startup-portal-program' },
        },
        {
          text: 'Milestone Stages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/milestone/review/:id/:sid/:aid?',
    name: 'startup-milestone-review',
    component: () => import('@/views/startup-portal/pages/programs/enter/milestone-manager/StageReviewQuestions.vue'),
    meta: {
      pageTitle: 'Milestone Stages',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'startup-portal-program' },
        },
        {
          text: 'Milestone Stages',
          to: { name: 'startup-milestone-stages' },
        },
        {
          text: 'Review',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/progress/details/:id',
    name: 'program-progress-details',
    component: () => import('@/views/startup-portal/pages/programs/enter/ProgressDetails.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Program Process',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'startup-portal-program' },
        },
        {
          text: 'Program Process',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/review/:pid/:apid/:aid?',
    name: 'startup-review-questions',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ReviewQuestion.vue'),
    meta: {
      pageTitle: 'Exercises',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'startup-portal-program' },
        },
        {
          text: 'Exercises',
          active: true,
        },
      ],
    },
  },
  {
    // APID = Review ID, AID = Participant ID, RID = Reviewer/Partner ID
    path: '/startup-portal/programs/review/evaluations/:pid/:apid/:aid',
    name: 'startup-review-evaluations',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/review-manager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Exercises Evaluations',
      breadcrumb: [
        {
          text: 'Programs',
          to: { name: 'startup-portal-program' },
        },
        {
          text: 'Exercises',
          to: { name: 'startup-review-questions' },
        },
        {
          text: 'Evaluations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/programs/:id/manage',
    name: 'startup-program-manager',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/Eventsindex.vue'),
    meta: {
      pageTitle: 'Event',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
          to: { name: 'startup-portal-events' },
        },
        {
          text: 'Event',
          active: true,
        },
      ],
    },
  },
]
