export default [
  {
    path: '/startup-portal/applications',
    name: 'startup-applications',
    component: () => import('@/views/startup-portal/pages/applications/Applications.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'My Applications',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Stratup Programs',
        },
        {
          text: 'My Applications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/competition-applications',
    name: '/startup-portal-competition-applications',
    component: () => import('@/views/startup-portal/pages/applications/MyApplications.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'My Applications',
      breadcrumb: [
        {
          text: 'Oppourtunities',
        },
        {
          text: 'Competitions',
        },
        {
          text: 'My Applications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/applications/rounds/:id/:aid?/:sid?',
    name: 'application-round',
    component: () => import('@/views/startup-portal/pages/applications/Rounds.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Round Details',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
          to: { name: 'startup-portal-program' },
        },
        {
          text: 'My Applications',
        },
        {
          text: 'Round Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/events/applications/rounds/:id/:aid?/:sid?',
    name: 'application-round',
    component: () => import('@/views/startup-portal/pages/applications/EventsRounds.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Round Details',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
          to: { name: 'startup-portal-events' },
        },
        {
          text: 'My Applications',
        },
        {
          text: 'Round Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/competition/applications/rounds/:id/:aid?/:sid?',
    name: 'application-round',
    component: () => import('@/views/startup-portal/pages/applications/CompetitionRounds.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Round Details',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
          to: { name: 'startup-portal-competition' },
        },
        {
          text: 'My Applications',
        },
        {
          text: 'Round Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/applications/details/:pid/:apid/:aid?',
    name: 'startup-application-form',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
    },
  },
  {
    path: '/startup-portal/programs/:pid/applications/:apid/applicant/:aid/evaluations/',
    name: 'startup-view-evaluation',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Application Evaluation',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
          to: { name: 'startup-portal-program' },
        },
        {
          text: 'My Applications',
        },
        {
          text: 'Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/event/:pid/applications/:apid/applicant/:aid/evaluations/',
    name: 'startup-event-view-evaluation',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/EventViewEvaluation.vue'),
    meta: {
      pageTitle: 'Application Evaluation',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
          to: { name: 'startup-portal-events' },
        },
        {
          text: 'My Applications',
        },
        {
          text: 'Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/competition/:pid/applications/:apid/applicant/:aid/evaluations/',
    name: 'startup-competition-view-evaluation',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/CompetitionViewEvaluation.vue'),
    meta: {
      pageTitle: 'Application Evaluation',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
          to: { name: 'startup-portal-competition' },
        },
        {
          text: 'My Applications',
        },
        {
          text: 'Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/application/evaluations/:pid/:apid/:aid',
    name: 'startup-view-evaluation',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ViewEvaluation.vue'),
    meta: {
      pageTitle: 'Application Evaluation',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Applications',
          active: true,
        },
      ],
    },
  },
  {
    // PID = Program, APID = Application ID, AID = Applicant ID, RID = Reviewer/Partner ID
    path: '/startup-portal/programs/:pid/applications/:apid/applicant/:aid/partner/:rid',
    name: 'startup-application-score',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ApplicationScore.vue'),
    meta: {
      pageTitle: 'Score Response',
      breadcrumb: [
        {
          text: 'Startup',
        },
        {
          text: 'Applications',
          active: true,
        },
      ],
    },
  },
]
