export default [
  {
    path: '/startup-portal/apply-now',
    name: 'startup-apply-now',
    component: () => import('@/views/startup-portal/pages/apply-now/ApplyNow.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Open for Registration',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
          to: { name: 'startup-portal-program' },
        },
        {
          text: 'Open for Registration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/competition-registration',
    name: '/startup-portal/competition-registration',
    component: () => import('@/views/startup-portal/pages/apply-now/Registration.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      pageTitle: 'Open for Registration',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
          to: { name: 'startup-portal-competition' },
        },
        {
          text: 'Open for Registration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/application/:pid/:apid/:aid?',
    name: 'startup-application-question',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/ApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Startup Programs',
          to: { name: 'startup-portal-program' },
        },
        {
          text: 'Open for Registration',
        },
        {
          text: 'Apply Now',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/event/application/:pid/:apid/:aid?',
    name: 'startup-event-application-question',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/EventsApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
          to: { name: 'startup-portal-events' },
        },
        {
          text: 'Open for Registration',
        },
        {
          text: 'Apply Now',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/competition/application/:pid/:apid/:aid?',
    name: 'startup-competition-open-question',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/CompetitionApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
          to: { name: 'startup-portal-competition' },
        },
        {
          text: 'Open for Registration',
        },
        {
          text: 'Apply Now',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/events/application/:pid/:apid/:aid?',
    name: 'startup-event-open-question',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/EventsApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Events',
          to: { name: 'startup-portal-events' },
        },
        {
          text: 'Open for Registration',
        },
        {
          text: 'Apply Now',
          active: true,
        },
      ],
    },
  },
  {
    path: '/startup-portal/competition/application/:pid/:apid/:aid?',
    name: 'startup-competition-open-question',
    component: () => import('@/views/incubator-portal/programs/pages/startups/program-manager/application-manager/CompetitionApplicationQuestion.vue'),
    meta: {
      pageTitle: 'Application Questions',
      breadcrumb: [
        {
          text: 'Opportunities',
        },
        {
          text: 'Competitions',
          to: { name: 'startup-portal-competition' },
        },
        {
          text: 'Open for Registration',
        },
        {
          text: 'Apply Now',
          active: true,
        },
      ],
    },
  },
]
